<template>
    <VueFinalModal>
        <div class="modal show d-block">
            <div class="modal-dialog modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Confirmar</h5>
                        <button type="button" class="btn-close" @click="$emit('onClose')"></button>
                    </div>
                    <div class="modal-body">
                        <slot />
                    </div>
                    <div class="modal-footer">
                        <button
                            class="btn btn-sm btn-success"
                            @click="$emit('onConfirm')"
                        >
                            Confirmar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </VueFinalModal>
</template>
<script>
import { VueFinalModal } from 'vue-final-modal';

export default {
    name: 'ModalConfirm',
    components: { VueFinalModal },
};
</script>
<style lang="scss"></style>
