<template>
    <div class="weekView w-100">
        <!-- Header -->
        <div class="row">
            <div class="col" v-if="weekDays.length">
                <div class="month">{{ weekDays[0].toFormat('LLLL yyyy', { locale: 'co' }) }}</div>
            </div>
            <div class="col" v-if="weekDays.length && showMonthinRightSide">
                <div class="month ms-auto text-end">
                    {{ weekDays[weekDays.length - 1].toFormat('LLLL yyyy', { locale: 'co' }) }}
                </div>
            </div>
        </div>
        <div class="row g-0 seven-cols">
            <div class="col-sm-1 info__col">
                <div class="day__title">
                    <span class="day__number">&nbsp;</span>
                    <span class="day__week_day"></span>
                </div>
            </div>
            <div class="col-sm-1 day__col" v-for="(day, i) in weekDays" :key="i">
                <div class="day__title">
                    <span class="day__number">{{ day.toFormat('dd') }}</span>&nbsp;
                    <span class="day__week_day">{{ day.toFormat('EEE', { locale: 'co' }) }}</span>
                </div>
            </div>
        </div>
        <!-- Hours Grid -->
        <div class="row g-0 seven-cols" v-for="(hourRow, e) in hoursDay" :key="e">
            <div class="col-sm-1 info__col d-flex justify-content-end">
                <div class="day__grid_guide">
                    <div class="day__grid_hour">
                        <span class="hour__number">{{ hourRow.toFormat('h') }}</span>
                        <span class="hour__meridean">{{ hourRow.toFormat('a') }}</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-1 grid__day_col" v-for="(day, i) in weekDays" :key="i">
                <HourGrid :events="getEvents(day, hourRow)" @onEventClick="handleEventClick"
                    @onEventCloseClick="handleEventCloseClick" @onViewEventClick="handleViewEventClick"
                    @onCancelEventClick="handleCancelEventClick" :hourHeight="hourHeight" />
            </div>
        </div>
    </div>
</template>

<script>
import { computed, ref, onBeforeMount } from 'vue';
import { DateTime } from 'luxon';
import HourGrid from './components/HourGrid.vue';

export default {
    name: 'WeekCalendar',
    components: { HourGrid },
    props: {
        currentWeek: Object,
        weekDays: Array,
        currentDay: Object,
        hourHeight: {
            type: Number,
            required: true,
        },
        fiveMinH: { type: Number, default: 7 },
        hourMin: { type: Number, default: 7 },
        hourMax: { type: Number, default: 18 },
        blocked: { type: Array, default: () => [] },
        intervalsDisabled: { type: Array, default: () => [] },
        events: { type: Array, default: () => [] },
    },
    setup(props, { emit }) {
        const hoursDay = ref([]);
        // const cssProps = computed(() => ({
        //     '--height': `${props.fiveMinH}px`,
        // }));

        const getHoursDays = () => {
            hoursDay.value.length = 0;
            let hours = DateTime.now().startOf('day');
            // console.log(hours);
            for (let i = 0; i < 24; i += 1) {
                const h = Number(hours.toFormat('H'));
                if (props.hourMin <= h && h < props.hourMax) {
                    hoursDay.value.push(DateTime.fromISO(hours.toISO()));
                }
                hours = hours.plus({ hours: 1 });
            }
        };

        const getEvents = (day, hour) => {
            const dayObj = day.toObject();
            const dateObj = DateTime.fromObject({ ...dayObj, hour: hour.hour });
            const minHour = DateTime.fromObject({ ...dayObj, hour: props.hourMin });
            const maxHour = DateTime.fromObject({ ...dayObj, hour: props.hourMax });
            const blockeds = props.blocked
                .filter((b) => b.weekDay === dateObj.weekday)
                .map((b) => {
                    const copy = { ...b };
                    const { startHour, startMin, startSec } = b;
                    const { endHour, endMin, endSec } = b;
                    const dStart = DateTime.fromObject({
                        ...dayObj,
                        hour: startHour,
                        minute: startMin,
                        second: startSec,
                    });
                    const dEnd = DateTime.fromObject({
                        ...dayObj,
                        hour: endHour,
                        minute: endMin,
                        second: endSec,
                    });
                    if (dStart < minHour) {
                        copy.timeStart = minHour.toISO();
                    } else {
                        copy.timeStart = dStart.toISO();
                    }
                    if (dEnd >= maxHour) {
                        copy.timeEnd = maxHour.toISO();
                    } else {
                        copy.timeEnd = dEnd.toISO();
                    }
                    copy.type = copy.class;
                    return copy;
                })
                .concat(
                    props.intervalsDisabled
                        .filter((b) => b.timeStart)
                        .map((b) => {
                            const copy = { ...b };
                            copy.type = 'blocked';
                            return copy;
                            // eslint-disable-next-line comma-dangle
                        })
                );
            const events = [...blockeds, ...props.events].filter((e) => {
                const timeStart = DateTime.fromISO(e.timeStart);
                if (dateObj <= timeStart && timeStart < dateObj.plus({ hour: 1 })) {
                    return true;
                }
                return false;
            });
            return events;
        };

        const handleEventClick = (item) => {
            emit('onEventClick', item);
        };
        const handleEventCloseClick = (item) => {
            emit('onEventCloseClick', item);
        };
        const handleViewEventClick = (item) => {
            emit('onViewEventClick', item);
        };
        const handleCancelEventClick = (item) => {
            emit('onCancelEventClick', item);
        };

        const showMonthinRightSide = computed(() => {
            const day0 = props.weekDays[0].month;
            const day1 = props.weekDays[props.weekDays.length - 1].month;
            return day0 !== day1;
        });

        onBeforeMount(() => {
            getHoursDays();
        });

        return {
            // cssProps,
            hoursDay,
            DateTime,
            showMonthinRightSide,
            getEvents,
            handleEventClick,
            handleEventCloseClick,
            handleViewEventClick,
            handleCancelEventClick,
        };
    },
};
</script>

<style scoped>
.seven-cols {
    border-block-end: 1px solid #d0d0d0;
    padding-inline: 3px;
}

.seven-cols:first-child {
    border-block-start: 1px solid #d0d0d0;
    height: 32px;
}

.day__col .day__title {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    text-align: right;
    width: 100%;
    height: 100%;
}

.month {
    text-transform: capitalize;
}

.month,
.day__col .day__number,
.hour__number {
    font-weight: 700;
    font-size: 1.3em;
    line-height: 1.3em;
}

.day__col .day__week_day {
    text-transform: capitalize;
}

.day__col .day__week_day,
.hour__meridean {
    font-weight: 400;
    font-size: 0.9em;
    line-height: 1.45em;
}

.day__grid_hour {
    padding-inline: 3px;
}

.grid__day_col {
    border-inline-end: 1px solid #d0d0d0;
}

.grid__day_col:nth-child(2) {
    border-inline-start: 1px solid #d0d0d0;
}

@media (min-width: 576px) {

    .seven-cols .col-md-1,
    .seven-cols .col-sm-1,
    .seven-cols .col-lg-1 {
        width: 13.57142857142857%;
    }

    .seven-cols .col-md-1:first-child,
    .seven-cols .col-sm-1:first-child,
    .seven-cols .col-lg-1:first-child {
        width: 5%;
    }
}

@media (min-width: 992px) {

    .seven-cols .col-md-1,
    .seven-cols .col-sm-1,
    .seven-cols .col-lg-1 {
        width: 13.85714285714286%;
    }

    .seven-cols .col-md-1:first-child,
    .seven-cols .col-sm-1:first-child,
    .seven-cols .col-lg-1:first-child {
        width: 3%;
    }
}

/**
*  The following is not really needed in this case
*  Only to demonstrate the usage of @media for large screens
*/
@media (min-width: 1200px) {

    .seven-cols .col-md-1,
    .seven-cols .col-sm-1,
    .seven-cols .col-lg-1 {
        width: 13.85714285714286%;
    }

    .seven-cols .col-md-1:first-child,
    .seven-cols .col-sm-1:first-child,
    .seven-cols .col-lg-1:first-child {
        width: 3%;
    }
}
</style>
