<template>
    <div class="day__grid_minutes">
        <Event v-for="e in events" :style="{ top: `${getPosition(e)}px` }" :key="e.id" :event="e" :height="hourHeight"
            @onEventClick="handleEventClick" @onEventCloseClick="handleEventCloseClick"
            @onViewEventClick="handleViewEventClick" @onCancelEventClick="handleCancelEventClick" />
    </div>
</template>

<script>
import { computed } from 'vue';
import { DateTime } from 'luxon';
import Event from '../Event.vue';

export default {
    name: 'HourGrid',
    components: { Event },
    props: {
        hourHeight: {
            type: Number,
            required: true,
        },
        events: { type: Array, required: true },
    },
    setup(props, { emit }) {
        const hourHeightPx = computed(() => `${props.hourHeight}px`);

        const getPosition = (event) => {
            const { minute } = DateTime.fromISO(event.timeStart);
            // console.log('Minute:', minute);
            return Number(minute) * (props.hourHeight / 60);
        };

        const handleEventClick = (item) => {
            emit('onEventClick', item);
        };
        const handleEventCloseClick = (item) => {
            emit('onEventCloseClick', item);
        };
        const handleViewEventClick = (item) => {
            emit('onViewEventClick', item);
        };
        const handleCancelEventClick = (item) => {
            emit('onCancelEventClick', item);
        };

        return {
            hourHeightPx,
            getPosition,
            handleEventClick,
            handleEventCloseClick,
            handleViewEventClick,
            handleCancelEventClick,
        };
    },
};
</script>

<style scoped>
.day__grid_minutes {
    height: v-bind(hourHeightPx);
}

Event {
    position: absolute;
}
</style>
