<template>
    <div class="row">
        <div class="col-sm-12">
            <div class="darmin card">
                <div class="card-header pb-0 d-flex align-items-center">
                    <h5>{{ headerTitle }}</h5>
                    <div class="ms-auto">
                        <div class="btn-group">
                            <button
                                class="btn btn-primary previous px-3"
                                role="group"
                                @click.stop="handlePrev()"
                            >
                                <i class="fas fa-angle-left"></i>
                            </button>
                            <button
                                class="btn btn-primary px-2"
                                role="group"
                                @click.stop="handleToday()"
                            >
                                Hoy
                            </button>
                            <button
                                class="btn btn-primary next px-3"
                                role="group"
                                @click.stop="handleNext()"
                            >
                                <i class="fas fa-angle-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="calendar">
                        <WeekCalendar
                            v-if="view === 'week'"
                            v-bind="{
                                currentDay,
                                weekDays,
                                blocked,
                                events,
                                intervalsDisabled,
                                hourMin,
                                hourMax,
                            }"
                            @onEventClick="handleEventClick"
                            @onEventCloseClick="handleEventCloseClick"
                            @onViewEventClick="handleViewEventClick"
                            @onCancelEventClick="handleCancelEventClick"
                            :hourHeight="hourHeight"
                        />
                        <DayCalendar
                            v-else
                            v-bind="{
                                currentDay,
                                weekDays,
                                blocked,
                                events,
                                intervalsDisabled,
                                hourMin,
                                hourMax,
                            }"
                            @onEventClick="handleEventClick"
                            @onEventCloseClick="handleEventCloseClick"
                            @onViewEventClick="handleViewEventClick"
                            @onCancelEventClick="handleCancelEventClick"
                            :hourHeight="hourHeight"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onBeforeMount, ref, computed } from 'vue';
import { DateTime } from 'luxon';

import WeekCalendar from './week.vue';
import DayCalendar from './day.vue';

export default {
    name: 'DarminCalendar',
    components: { WeekCalendar, DayCalendar },
    props: {
        view: {
            type: String,
            default: 'week',
        },
        hourHeight: {
            type: Number,
            required: true,
        },
        hourMin: Number,
        hourMax: Number,
        events: { type: Array, default: () => [] },
        intervalsDisabled: { type: Array, default: () => [] },
        especialista: String,
    },
    setup(props, { emit }) {
        const weekDays = ref([]);
        const currentDay = ref();
        const currentWeekStart = ref();
        const currentMonthStart = ref();

        const handleGetData = async () => {
            // console.log('props.especialista', props.especialista);
            if (props.especialista) {
                emit('onGetIntervalDisabled');
                let currentStart;
                switch (props.view) {
                    case 'week':
                    default:
                        currentStart = currentWeekStart.value;
                        break;
                }
                emit('onGetCitas', currentStart);
            }
        };

        const getWeekDays = () => {
            weekDays.value.length = 0;
            let days = currentWeekStart.value;
            while (weekDays.value.length < 7) {
                weekDays.value.push(DateTime.fromISO(days.toISO()));
                days = days.plus({ days: 1 });
            }
            handleGetData();
        };

        const headerTitle = computed(() => {
            switch (props.view) {
                case 'day':
                    return currentDay.value?.toFormat('dd/LLLL/yyyy', { locale: 'co' });
                case 'week':
                default:
                    return 'Calendario Semanal';
            }
        });

        const nextWeek = () => {
            currentWeekStart.value = currentWeekStart.value.plus({ week: 1 });
            currentMonthStart.value = currentWeekStart.value.startOf('month');
            currentDay.value = currentWeekStart.value;
            getWeekDays();
        };

        const nextDay = () => {
            currentDay.value = currentDay.value.plus({ days: 1 });
            currentWeekStart.value = currentWeekStart.value.startOf('week');
            currentMonthStart.value = currentWeekStart.value.startOf('month');
            getWeekDays();
        };

        const prevWeek = () => {
            currentWeekStart.value = currentWeekStart.value.minus({ week: 1 });
            currentMonthStart.value = currentWeekStart.value.startOf('month');
            currentDay.value = currentWeekStart.value;
            getWeekDays();
        };

        const prevDay = () => {
            currentDay.value = currentDay.value.minus({ days: 1 });
            currentWeekStart.value = currentWeekStart.value.startOf('week');
            currentMonthStart.value = currentWeekStart.value.startOf('month');
            getWeekDays();
        };

        const today = () => {
            currentDay.value = DateTime.now();
            currentWeekStart.value = currentDay.value.startOf('week');
            currentMonthStart.value = currentWeekStart.value.startOf('month');
            getWeekDays();
        };

        const handleNext = () => {
            switch (props.view) {
                case 'day':
                    nextDay();
                    break;
                case 'week':
                default:
                    nextWeek();
                    break;
            }
        };

        const handlePrev = () => {
            switch (props.view) {
                case 'day':
                    prevDay();
                    break;
                case 'week':
                default:
                    prevWeek();
                    break;
            }
        };

        const handleToday = () => {
            switch (props.view) {
                case 'week':
                default:
                    today();
                    break;
            }
        };

        const blocked = computed(
            () =>
                // eslint-disable-next-line implicit-arrow-linebreak, comma-dangle
                props.intervalsDisabled?.filter((b) => b.weekDay !== undefined)
            // eslint-disable-next-line function-paren-newline
        );

        const handleEventClick = (item) => {
            emit('onEventClick', item);
        };
        const handleEventCloseClick = (item) => {
            emit('onEventCloseClick', item);
        };
        const handleViewEventClick = (item) => {
            emit('onViewEventClick', item);
        };
        const handleCancelEventClick = async (item) => {
            await emit('onCancelEventClick', item);
            // handleGetData();
        };

        onBeforeMount(async () => {
            today();
        });

        const log = () => {
            console.log('Esto es un log');
        };

        return {
            currentDay,
            currentWeekStart,
            currentMonthStart,
            weekDays,
            headerTitle,
            blocked,
            handleNext,
            handlePrev,
            handleToday,
            handleEventClick,
            handleEventCloseClick,
            handleViewEventClick,
            handleCancelEventClick,
            handleGetData,
            log,
        };
    },
};
</script>

<style scoped></style>
